import React from 'react';
import type { FC } from 'react';
import {
    Box,
    makeStyles
} from '@material-ui/core';
import type { Theme } from '../theme';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        padding: theme.spacing(3),
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000
    }
}));

const Unauthorized: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box width={400}>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    You are unauthorized.
                </Typography>
            </Box>
        </div>
    );
};

export default Unauthorized;
