import React from 'react';
import type { FC } from 'react';

interface LogoProps {
    [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => (
    <img
        alt="Logo"
        style={{
            maxHeight: '40px'
        }}
        src="/logo.png"
        {...props}
    />
);

export default Logo;