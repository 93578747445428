import React, {
    Suspense,
    Fragment,
    lazy
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from '../src/layouts/DashboardLayout';
import MainLayout from '../src/layouts/MainLayout';
import LoadingScreen from '../src/components/LoadingScreen';
import AuthGuard from '../src/components/AuthGuard';
import GuestGuard from '../src/components/GuestGuard';

type Routes = {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {
                                        route.routes
                                            ? renderRoutes(route.routes)
                                            : <Component {...props} />
                                    }
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('../src/views/errors/NotFoundView'))
    },
    {
        exact: true,
        layout: MainLayout,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('../src/views/auth/LoginView'))
    },
    {
        exact: true,
        layout: MainLayout,
        path: '/register',
        component: lazy(() => import('../src/views/auth/RegisterView'))
    },
    {
        path: '/admin',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/admin/account',
                component: lazy(() => import('../src/views/account/AccountView'))
            },
            {
                exact: true,
                path: '/admin/management/organizations',
                component: lazy(() => import('../src/views/organization/OrganizationListView'))
            },
            {
                exact: true,
                path: '/admin/management/organizations/create',
                component: lazy(() => import('../src/views/organization/OrganizationCreateView'))
            },
            {
                exact: true,
                path: '/admin/management/organizations/:organizationId',
                component: lazy(() => import('../src/views/organization/OrganizationDetailsView'))
            },
            {
                exact: true,
                path: '/admin/management/organizations/:organizationId/edit',
                component: lazy(() => import('../src/views/organization/OrganizationEditView'))
            },
            {
                exact: true,
                path: '/admin/management/teams',
                component: lazy(() => import('../src/views/team/TeamListView'))
            },
            {
                exact: true,
                path: '/admin/management/teams/create',
                component: lazy(() => import('../src/views/team/TeamCreateView'))
            },
            {
                exact: true,
                path: '/admin/management/teams/:teamId',
                component: lazy(() => import('../src/views/team/TeamDetailsView'))
            },
            {
                exact: true,
                path: '/admin/management/teams/:teamId/edit',
                component: lazy(() => import('../src/views/team/TeamEditView'))
            },
            {
                exact: true,
                path: '/admin/management/users',
                component: lazy(() => import('../src/views/user/UserListView'))
            },
            {
                exact: true,
                path: '/admin/management/users/create',
                component: lazy(() => import('../src/views/user/UserCreateView'))
            },
            {
                exact: true,
                path: '/admin/management/users/:userId',
                component: lazy(() => import('../src/views/user/UserDetailsView'))
            },
            {
                exact: true,
                path: '/admin/management/users/:userId/edit',
                component: lazy(() => import('../src/views/user/UserEditView'))
            },
            {
                exact: true,
                path: '/admin/shop/customers',
                component: lazy(() => import('../src/views/customer/CustomerListView'))
            },
            {
                exact: true,
                path: '/admin/shop/customers/:customerId',
                component: lazy(() => import('../src/views/customer/CustomerDetailsView'))
            },
            {
                exact: true,
                path: '/admin/shop/customers/:customerId/edit',
                component: lazy(() => import('../src/views/customer/CustomerEditView'))
            },
            {
                exact: true,
                path: '/admin/shop/orders',
                component: lazy(() => import('../src/views/order/OrderListView'))
            },
            {
                exact: true,
                path: '/admin/shop/orders/:orderId',
                component: lazy(() => import('../src/views/order/OrderDetailsView'))
            },
            {
                exact: true,
                path: '/admin/management/items',
                component: lazy(() => import('../src/views/item/ItemListView'))
            },
            {
                exact: true,
                path: '/admin/management/items/create',
                component: lazy(() => import('../src/views/item/ItemCreateView'))
            },
            {
                exact: true,
                path: '/admin/management/items/:itemId',
                component: lazy(() => import('../src/views/item/ItemDetailsView'))
            },
            {
                exact: true,
                path: '/admin/management/items/:itemId/edit',
                component: lazy(() => import('../src/views/item/ItemEditView'))
            },
            {
                exact: true,
                path: '/admin/management',
                component: () => <Redirect to="/admin/shop/customers" />
            },
//             // {
//             //   exact: true,
//             //   path: '/admin/reports/dashboard',
//             //   component: lazy(() => import('../src/views/reports/DashboardView'))
//             // },
//             // {
//             //   exact: true,
//             //   path: '/admin/reports/dashboard-alternative',
//             //   component: lazy(() => import('../src/views/reports/DashboardAlternativeView'))
//             // },
//             // {
//             //   exact: true,
//             //   path: '/admin/reports',
//             //   component: () => <Redirect to="/admin/reports/dashboard" />
//             // },
//             // {
//             //   exact: true,
//             //   path: '/admin',
//             //   component: () => <Redirect to="/admin/reports/dashboard" />
//             // },
            {
                path: '/admin/*',
                layout: DashboardLayout,
                component: lazy(() => import('../src/views/errors/NotFoundView'))
            },
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: () => <Redirect to="/admin/account" />
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

export default routes;
