import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Toolbar,
    Hidden,
    Typography,
    makeStyles
} from '@material-ui/core';
import { APP_NAME, APP_VERSION } from '../../constants';
import Logo from '../../components/Logo';

interface TopBarProps {
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default
    },
    toolbar: {
        height: 64
    },
    logo: {
        marginRight: theme.spacing(2)
    },
    link: {
        fontWeight: theme.typography.fontWeightMedium,
        '& + &': {
            marginLeft: theme.spacing(2)
        }
    },
    divider: {
        width: 1,
        height: 32,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
    const classes = useStyles();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            color="default"
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/">
                    <Logo className={classes.logo} />
                </RouterLink>
                <Hidden mdDown>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {APP_NAME}
                        {' Version '}
                        {APP_VERSION}
                    </Typography>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string
};

export default TopBar;
