import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import { firebaseConfig } from '../config';

if(!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export const { auth, functions } = firebase;
export const firestore = firebase.firestore();
firestore.settings({ ignoreUndefinedProperties: true });
export const config = firebaseConfig;
export const fieldValue = firebase.firestore.FieldValue;
export const timeStamp = firebase.firestore.Timestamp;
export const aggreData = firestore.collection('AGGREGATE_DATA').doc('AGGREGATE_DATA');
export const userRef = firestore.collection('users');
export const orgRef = firestore.collection('organizations');
export const linkRef = firestore.collection('links');
export const teamRef = firestore.collection('teams');
export const itemRef = firestore.collection('items');
export const orderRef = firestore.collection('orders');
export const statsRef = firestore.collection('stats');
export type CollectionRef = firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
export type Query = firebase.firestore.Query<firebase.firestore.DocumentData>;
export type User = firebase.User;
export default firebase;
