import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Unauthorized from '../components/Unauthorized';
import useAuth from '../hooks/useAuth';
import { UserRole } from '../types/user';

interface GuestGuardProps {
    children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
    const { isAuthenticated, user } = useAuth();
    console.log(user);
    if(isAuthenticated && user?.role !== UserRole.Admin) {
        return <Unauthorized />;
    }
    if(isAuthenticated) {
        return <Redirect to="/admin/account" />;
    }
    return (
        <>
            {children}
        </>
    );
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
