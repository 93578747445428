import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import type { User } from '../types/user';
import SplashScreen from '../components/SplashScreen';
import { auth, timeStamp, userRef } from '../utils/firebase';
import { DocMeta, Metadata, MetaUser } from '../types/metadata';

interface AuthState {
    isInitialised: boolean;
    isAuthenticated: boolean;
    user: User | undefined;
}

interface DocObject{
    [index:string]: any,
    metadata: DocMeta
}


interface AuthContextValue extends AuthState {
    method: 'FirebaseAuth',
    createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
    changePassword: (password: string) => Promise<any>;
    deleteCurrentUser: () => Promise<any>;
    buildMetadata: (object: any, newOwner?: MetaUser) => object;
    logout: () => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type AuthStateChangedAction = {
    type: 'AUTH_STATE_CHANGED';
    payload: {
        isAuthenticated: boolean;
        user: User | null;
    };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: undefined
};

const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'AUTH_STATE_CHANGED': {
            const { isAuthenticated, user } = action.payload;
            if(user){
                return {
                    ...state,
                    isAuthenticated,
                    isInitialised: true,
                    user: user
                };
            }
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    method: 'FirebaseAuth',
    changePassword: () => Promise.resolve(),
    createUserWithEmailAndPassword: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    deleteCurrentUser: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    buildMetadata: () => Object,
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const signInWithEmailAndPassword = (email: string, password: string): Promise<any> => auth().signInWithEmailAndPassword(email, password);

    const createUserWithEmailAndPassword = async (email: string, password: string): Promise<any> => auth().createUserWithEmailAndPassword(email, password);

    const changePassword = async (password: string): Promise<void> => auth()?.currentUser?.updatePassword(password);

    const logout = (): Promise<void> => auth().signOut();

    const deleteCurrentUser = async (): Promise<void> => auth()?.currentUser?.delete();

    const buildMetadata = (object: any, newOwner?: MetaUser) => {
        const now = timeStamp.now();
        const user: MetaUser = {
            name: `${state?.user?.name}`,
            id: `${state?.user?.id}`,
            avatar: `${state?.user?.avatar}`,
        };
        const nowByUser: Metadata = {
            user: user,
            time: {
                seconds: now.seconds,
                nanoseconds: now.nanoseconds
            }
        };
        const updatedMetadata: DocMeta = {
            ...object.metadata,
            updated: nowByUser,
        };
        (newOwner || !object?.metadata?.owner)  && (updatedMetadata.owner = newOwner || user)

        return {
            owner: user,
            // @ts-ignore
            created: nowByUser,
            // @ts-ignore
            updated: nowByUser,
            ...updatedMetadata,
        } as DocMeta;
    };

    useEffect(() => {
        const unsubscribe = auth().onAuthStateChanged(async (user) => {
            if(user) {
                const role = (await user.getIdTokenResult() as any).claims.role;
                console.log(role);
                if(role !== 'Admin' && role !== 'Staff'){
                    await logout();
                } else {
                    userRef.doc(user.uid).onSnapshot(
                        (snapshot => dispatch({
                                type: 'AUTH_STATE_CHANGED',
                                payload: {
                                    isAuthenticated: true,
                                    user: snapshot.data() as User
                                }
                            }
                        )));
                }
            } else {
                dispatch({
                    type: 'AUTH_STATE_CHANGED',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        });
        return unsubscribe;
    }, [dispatch]);

    if(!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'FirebaseAuth',
                createUserWithEmailAndPassword,
                signInWithEmailAndPassword,
                deleteCurrentUser,
                changePassword,
                buildMetadata,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
