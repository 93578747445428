import React from 'react';
import type { FC } from 'react';
import './App.css';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
// import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from '../src/components/GlobalStyles';
import ScrollReset from '../src/components/ScrollReset';
import CookiesNotification from '../src/components/CookiesNotification';
import GoogleAnalytics from '../src/components/GoogleAnalytics';
import SettingsNotification from '../src/components/SettingsNotification';
import { AuthProvider } from '../src/contexts/FirebaseAuthContext';
import useSettings from '../src/hooks/useSettings';
import { createTheme } from '../src/theme';
import routes, { renderRoutes } from '../src/routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    type: settings.type,
  });
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          {/*<MuiPickersUtilsProvider utils={MomentUtils}>*/}
            <SnackbarProvider
              dense
              maxSnack={3}
            >
              <Router history={history}>
                <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  <GoogleAnalytics />
                  <CookiesNotification />
                  <SettingsNotification />
                  {renderRoutes(routes)}
                </AuthProvider>
              </Router>
            </SnackbarProvider>
          {/*</MuiPickersUtilsProvider>*/}
        </StylesProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
