import type {FC, ReactNode} from 'react';
import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import {UserRole} from '../types/user';

interface AuthGuardProps {
    children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
    const { isAuthenticated, user } = useAuth();

    if(isAuthenticated && (user?.role === UserRole.Admin || user?.role === UserRole.Staff)) {
        return <>{children}</>;
    }
    return <Redirect to="/login" />;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
