import React, {
    useState,
} from 'react';
import type { FC } from 'react';
import {
    Switch,
    SwitchProps
} from '@material-ui/core';
import useSettings from '../../../hooks/useSettings';
import type { Theme } from '../../../theme';
import { withStyles, createStyles } from '@material-ui/styles';

interface Props extends SwitchProps {
    classes: any;
}

const IOSSwitch = withStyles((theme: Theme) => createStyles({
    root: {
        width: 45,
        height: 24,
        padding: '3px',
        margin: theme.spacing(1),
    },
    switchBase: {
        background: theme.palette.grey[900],
        color: 'black',
        cursor: 'pointer',
        height: '24px',
        width: '24px',
        position: 'absolute',
        top: '0',
        bottom: '0',
        // margin: '0 0 0 1px',
        '&$checked': {
            color: theme.palette.common.white,
            background: theme.palette.common.white,
            '& + $focusVisible': {
                'box-shadow': '0 0 1px #2196f3'
            },
            '& + $track': {
                backgroundColor: '#ee8959',
                opacity: 1,
            },
            '&:before': {
                content: '""',
                background: "lightBlue url('/images/SVG/sunny.svg')",
                color: theme.palette.common.white,
                'box-shadow': '0 0px 15px #2020203d',
                'border-radius': '50%',
                'background-repeat': 'no-repeat',
                'background-position': 'center',
            }
        },
        '&$focusVisible $thumb': {
            border: '6px solid #fff',
        },
        '&:before': {
            background: "#000000cc url('/images/SVG/night.svg')",
            content: '""',
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            top: '0px',
            height: '24px',
            width: '24px',
            margin: 'auto 0',
            transform: 'scale(.8)',
            'border-radius': '50%',
            'box-shadow': '0 0px 15px #2020203d',
            'background-repeat': 'no-repeat',
            'background-position': 'center',
        }
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[700],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}),)(({ classes, ...props }: Props) => (
    <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        icon={<></>}
        checkedIcon={<></>}
        classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
        }}
        {...props}
    />
));

const Settings: FC = () => {
    const { settings, saveSettings } = useSettings();
    const [checked, setChecked] = useState(settings.theme === 'LIGHT');
    const handleChange = (): void => {
        setChecked(!checked);
        saveSettings({
            direction: settings.direction,
            responsiveFontSizes: settings.responsiveFontSizes,
            theme: !checked ? 'LIGHT' : 'ONE_DARK'
        });
    };

    return (
        <IOSSwitch
            checked={checked}
            onChange={handleChange}
        />
    );
};

export default Settings;
