/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    ListSubheader,
    Typography,
    makeStyles
} from '@material-ui/core';
import {
    Briefcase as OrgIcon,
    CreditCard as CustomerIcon,
    ShoppingBag as OrdersIcon,
    ShoppingCart as ItemsIcon,
    Users as TeamsIcon,
    User as UsersIcon,
} from 'react-feather';
import Logo from '../../../components/Logo';
import useAuth from '../../../hooks/useAuth';
import NavItem from './NavItem';

interface NavBarProps {
    onMobileClose?: () => void;
    openMobile?: boolean;
}

interface Item {
    href?: string;
    icon?: ReactNode;
    info?: ReactNode;
    items?: Item[];
    title: string;
}

interface Section {
    items: Item[];
    subheader: string;
}

const sections: Section[] = [
    // {
    //   subheader: 'Reports',
    //   items: [
    //     {
    //       title: 'Dashboard',
    //       icon: PieChartIcon,
    //       href: '/admin/reports/dashboard'
    //     },
    //     {
    //       title: 'Dashboard Alternative',
    //       icon: BarChartIcon,
    //       href: '/admin/reports/dashboard-alternative'
    //     }
    //   ]
    // },
    {
        subheader: 'Management',
        items: [
            {
                title: 'Users',
                icon: UsersIcon,
                href: '/admin/management/users',
                items: [
                    {
                        title: 'List Users',
                        href: '/admin/management/users'
                    },
                    {
                        title: 'Create User',
                        href: '/admin/management/users/create'
                    }
                ]
            },
            {
                title: 'Teams',
                icon: TeamsIcon,
                href: '/admin/management/teams',
                items: [
                    {
                        title: 'List Teams',
                        href: '/admin/management/teams'
                    },
                    {
                        title: 'Create Team',
                        href: '/admin/management/teams/create'
                    }
                ]
            },
            {
                title: 'Organizations',
                icon: OrgIcon,
                href: '/admin/management/organizations',
                items: [
                    {
                        title: 'List Organizations',
                        href: '/admin/management/organizations'
                    },
                    {
                        title: 'Create Organization',
                        href: '/admin/management/organizations/create'
                    }
                ]
            },
            {
                title: 'Items',
                icon: ItemsIcon,
                href: '/admin/management/items',
                items: [
                    {
                        title: 'List Items',
                        href: '/admin/management/items'
                    },
                    {
                        title: 'Create Item',
                        href: '/admin/management/items/create'
                    }
                ]
            },
            // {
            //   title: 'Invoices',
            //   icon: ReceiptIcon,
            //   href: '/admin/management/invoices',
            //   items: [
            //     {
            //       title: 'List Invoices',
            //       href: '/admin/management/invoices'
            //     },
            //     {
            //       title: 'View Invoice',
            //       href: '/admin/management/invoices/1'
            //     }
            //   ]
            // }
        ]
    },
    {
        subheader: 'Shop',
        items: [
            {
                title: 'Customers',
                icon: CustomerIcon,
                href: '/admin/shop/customers',
            },
            {
                title: 'Orders',
                icon: OrdersIcon,
                href: '/admin/shop/orders',
            },
        ]
    },
];

function renderNavItems({
                            items,
                            pathname,
                            depth = 0
                        }: {
    items: Item[];
    pathname: string;
    depth?: number;
}) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({
                    acc, pathname, item, depth
                }), []
            )}
        </List>
    );
}

function reduceChildRoutes({
                               acc,
                               pathname,
                               item,
                               depth
                           }: {
    acc: any;
    pathname: string;
    item: Item;
    depth: number;
}) {
    const key = item.title + depth;

    if(item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        if(openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box
                        p={2}
                        display="flex"
                        justifyContent="center"
                    >
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <RouterLink to="/admin/account">
                            <Avatar
                                alt="User"
                                className={classes.avatar}
                                src={user?.avatar}
                            />
                        </RouterLink>
                    </Box>
                    <Box
                        mt={2}
                        textAlign="center"
                    >
                        <Link
                            component={RouterLink}
                            to="/admin/account"
                            variant="h5"
                            color="textPrimary"
                            underline="none"
                        >
                            {user?.name}
                        </Link>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            {`Your role: ${user?.role?.toUpperCase()}`}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {sections.map((section) => (
                        <List
                            key={section.subheader}
                            subheader={(
                                <ListSubheader
                                    disableGutters
                                    disableSticky
                                >
                                    {section.subheader}
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname
                            })}
                        </List>
                    ))}
                </Box>
                {/* <Divider /> */}
                {/* <Box p={2}> */}
                {/*  <Box */}
                {/*    p={2} */}
                {/*    borderRadius="borderRadius" */}
                {/*    bgcolor="background.dark" */}
                {/*  > */}
                {/*    <Typography */}
                {/*      variant="h6" */}
                {/*      color="textPrimary" */}
                {/*    > */}
                {/*      Need Help? */}
                {/*    </Typography> */}
                {/*    <Link */}
                {/*      variant="subtitle1" */}
                {/*      color="secondary" */}
                {/*      component={RouterLink} */}
                {/*      to="/docs" */}
                {/*    > */}
                {/*      Check our docs */}
                {/*    </Link> */}
                {/*  </Box> */}
                {/* </Box> */}
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
