import { FormikValues } from 'formik';
import { DocMeta } from './metadata';

export enum UserRole{
    Admin='Admin',
    Player='Player',
    Parent='Parent',
    Coach='Coach',
    Staff='Staff',
}
interface defaultModel{
    id: string,
    name: string,
    stats: string,
}
interface Team extends defaultModel{}
interface Organization extends defaultModel{}

export interface User extends Object{
    id: string;
    avatar: string;
    email: string;
    phone:string;
    name: string;
    isVerified: boolean;
    playerNumber?: number;
    team?: Team;
    organization?: Organization;
    role: UserRole|undefined;
    stats: string,
    metadata: DocMeta,
    [index:string]: any;
}

export interface UserForm extends Omit<User, 'metadata'|'isVerified'>, FormikValues{
    id: string;
    avatar: string;
    email: string;
    phone:string;
    name: string;
    isVerified: boolean;
    team: string;
    organization: string;
    role: UserRole|undefined;
    submit:any;
}

export interface UserFireConnect{
    status:{
        requesting: {},
        requested: {},
        timestamps: {}
    },
    data:any,
    ordered:any,
    fireErrors:any
}
